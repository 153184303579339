import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField
} from '@mui/material';
import moment from 'moment';
import { getFirestore, collection, query, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Navigation } from '@mui/icons-material';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'group', label: 'Group', alignRight: false },
  { id: 'qty', label: 'Qty', alignRight: false },
  { id: 'date', label: 'Event Date', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_event) => _event.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PreviousEventsPage() {
  const [events, setEvents] = useState([]);
  const [previousEvents, setPreviousEvents] = useState([]);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(true);
  const [groups, setGroups] = useState([]);
  const [row, setRow] = useState({});
  const navigate = useNavigate();

  const db = getFirestore();

  useEffect(() => {
    // Fetch events from Firestore
    
    // Fetch previous events from Firestore
    const fetchPreviousEvents = async () => {
      try {
        const eventsRef = collection(db, 'previous_events');
        const querySnapshot = await getDocs(eventsRef);
        const fetchedEvents = [];
        querySnapshot.forEach((doc) => {
          const event = doc.data();
          fetchedEvents.push({ ...event, id: doc.id });
        });
        setEvents(fetchedEvents)
        setPreviousEvents(fetchedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    const fetchGroups = async () => {
      try {
        const groupsRef = collection(db, 'groups');
        const querySnapshot = await getDocs(groupsRef);
        const fetchedGroups = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setGroups(fetchedGroups);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };
    
    fetchGroups();
    fetchPreviousEvents();
  }, [events]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleViewAttendance = () => {
    navigate(`previous-attendance?id=${row.id}`)
    handleCloseMenu();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setRow(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = events.map((event) => event.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteClick = () => async () => {
    try {
      await deleteDoc(doc(db, 'events', row.id));
      // Handle success and notify the user
      alert('Event deleted successfully');
    } catch (error) {
      // Handle error and notify the user
      alert('Error deleting event');
    }
  };

  const handleEditClick = () => {
    console.log(row)
    navigate(`information?id=${row.id}`)
    handleCloseMenu();
  };

  const downloadParticipantsCSV = () => {
    // Define CSV header
    const csvHeader = 'Event Name,Number of Requests\n';

    const allEvents = events;

    // Sort allEvents by the length of the `users` array in descending order
    const sortedEvents = allEvents.sort((a, b) => b.users.length - a.users.length);

    // Convert sorted event data to CSV rows
    const csvRows = sortedEvents.map(event => `${event.title.replace(/,/g, '')},${event.users.length}`).join('\n');

    // Combine the header and rows
    const csvString = csvHeader + csvRows;

    // Create a Blob with CSV data
    const blob = new Blob([csvString], { type: 'text/csv' });

    // Create a temporary link for downloading the CSV file
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "event_participation_report.csv");

    // Append the link to the document, trigger the download, and then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - events.length) : 0;

  const filteredEvents = applySortFilter(events, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredEvents.length && !!filterName;

  return (
    <>
      <Helmet>
        <title>Events | HelloVIP</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Events
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => navigate('/dashboard/events/information')}>
            New Event
          </Button>
        </Stack>

        <Card>
          <UserListToolbar searchName="Search event..." numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} downloadReport={downloadParticipantsCSV} />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={events.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={events.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((event) => {
                    const { id, title, groupIds, quantity, date, eventStatus } = event;
                    const selectedEvent = selected.indexOf(title) !== -1;
                    const groupNames = groupIds.map((groupId) => groups.find((group) => group.id === groupId)?.name || '');

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedEvent}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedEvent} onChange={(event) => handleClick(event, title)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {title}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {groupNames.join(', ')}
                        </TableCell>
                        <TableCell align="left">{quantity}</TableCell>
                        <TableCell align="left">{moment(date).format('MM/DD/YY HH:mm')}</TableCell>
                        {/* <TableCell align="left">{eventStatus}</TableCell> */}
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(mouseEvent) => handleOpenMenu(mouseEvent, event)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: 'center' }}>
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={events.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleViewAttendance}>
          <Iconify icon="eva:people-outline" sx={{ mr: 2 }} />
          View Attendance
        </MenuItem>


      </Popover>
    </>
  );
}

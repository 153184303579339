import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => {
  const dateOfBirth = faker.date.between('1950-01-01', '2003-12-31');
  return {
    id: faker.datatype.uuid(),
    avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
    name: faker.name.fullName(),
    dateOfBirth: dateOfBirth.toISOString().slice(0, 10),
    email: faker.internet.email(),
    company: faker.company.name(),
    isVerified: faker.datatype.boolean(),
    type: sample([
      'Gold',
      'Silver',
      'Bronze',
    ]),
    status: sample(['active', 'banned']),
    role: sample([
      'Leader',
      'Hr Manager',
      'UI Designer',
      'UX Designer',
      'UI/UX Designer',
      'Project Manager',
      'Backend Developer',
      'Full Stack Designer',
      'Front End Developer',
      'Full Stack Developer',
    ]),
  };
});

export default users;

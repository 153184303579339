import React from 'react';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { DatePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

class VideoUploadForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            video: null,
            date: new Date(),
            select1: '',
            select2: ''
        };
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleFileChange = (event) => {
        this.setState({
            video: event.target.files[0]
        });
    }

    // handleDateChange = (date) => {
    //     this.setState({
    //         date: date
    //     });
    // }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state);
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <TextField
                    name="title"
                    label="Title"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={this.state.title}
                    onChange={this.handleInputChange}
                />
                <TextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    value={this.state.description}
                    onChange={this.handleInputChange}
                    sx={{marginBottom: 3}}
                />
                <DatePicker
                    label="Select Date"
                    value={this.state.date}
                    onChange={this.handleDateChange}
                    renderInput={(props) => <TextField {...props} />}
                />
                <FormControl fullWidth sx={{marginBottom: 3}}>
                    <InputLabel id="demo-simple-select-label">Group</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.select1}
                        name="select1"
                        onChange={this.handleInputChange}
                    >
                        <MenuItem value={'Option1'}>Option 1</MenuItem>
                        <MenuItem value={'Option2'}>Option 2</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{marginBottom: 3}}>
                    <InputLabel id="demo-simple-select-label-2">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label-2"
                        id="demo-simple-select-2"
                        value={this.state.select2}
                        name="select2"
                        onChange={this.handleInputChange}
                    >
                        <MenuItem value={'Option1'}>Option 1</MenuItem>
                        <MenuItem value={'Option2'}>Option 2</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{marginBottom: 2}}>
                  <FileUploader  handleChange={() => console.log('Uplaoder')} name="file" types={["JPG", "PNG", "GIF"]} />
                </FormControl>
                <FormControl fullWidth sx={{marginBottom: 2}}>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date picker"
                  inputFormat="MM/dd/yyyy"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}
                {/* <DatePicker/> */}
                </FormControl>
                <input
                    accept="video/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={this.handleFileChange}
                />
                {/* <label htmlFor="contained-button-file">
                    <Button variant="contained" component="span">
                        Upload Video
                    </Button>
                </label> */}
                <Button variant="contained" color="primary" type="submit">
                    Submit
                </Button>
            </form>
        );
    }
}

export default VideoUploadForm;

// Router.js
import { useRoutes, Navigate } from 'react-router-dom';
import ProtectedRoute from './protectedRoute';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ConfigurationPage from './pages/ConfigurationPage';
import EventsPage from './pages/EventsPage';
import AttendancePage from './pages/AttendancePage';
import PushNotificationsPage from './pages/PushNotificationPage';
import NewEventPage from './pages/NewEventPage';
import NewUserPage from './pages/NewUserPage';
import NewPushNotificationPage from './pages/NewPushNotificationPage';
import CategoriesPage from './pages/CategoriesPage';
import GroupsPage from './pages/GroupsPage';
import NewCategoryPage from './pages/NewCategoryPage';
import NewGroupPage from './pages/NewGroupPage';
import WinnersReportPage from './pages/WinnersReportPage';
import PreviousEventsPage from './pages/PreviousEventsPage';
import PreviousAttendancePage from './pages/PreviousAttendancePage';

export default function Router() {
  const isAuthenticated = localStorage.getItem('token');

  const routes = useRoutes([
    {
      path: '/',
      element: isAuthenticated ? <Navigate to="/dashboard/user" replace /> : <Navigate to="/login" replace />,
      index: true,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        // { path: 'app', element: <ProtectedRoute element={<DashboardAppPage />} /> },
        { path: 'configuration', element: <ProtectedRoute element={<ConfigurationPage />} /> },
        { path: 'user', element: <ProtectedRoute element={<UserPage />} /> },
        { path: 'user/information', element: <ProtectedRoute element={<NewUserPage />} /> },
        { path: 'events', element: <ProtectedRoute element={<EventsPage />} /> },
        { path: 'events/attendance', element: <ProtectedRoute element={<AttendancePage />} /> },
        { path: 'previous-events/previous-attendance', element: <ProtectedRoute element={<PreviousAttendancePage />} /> },
        { path: 'events/information', element: <ProtectedRoute element={<NewEventPage />} /> },
        { path: 'push-notifications', element: <ProtectedRoute element={<PushNotificationsPage />} /> },
        { path: 'push-notifications/information', element: <ProtectedRoute element={<NewPushNotificationPage />} /> },
        { path: 'categories', element: <ProtectedRoute element={<CategoriesPage />} /> },
        { path: 'categories/information', element: <ProtectedRoute element={<NewCategoryPage />} /> },
        { path: 'groups/information', element: <ProtectedRoute element={<NewGroupPage />} /> },
        { path: 'groups', element: <ProtectedRoute element={<GroupsPage />} /> },
        { path: 'products', element: <ProtectedRoute element={<ProductsPage />} /> },
        { path: 'winner-reports', element: <ProtectedRoute element={<WinnersReportPage />} /> },
        { path: 'previous-events', element: <ProtectedRoute element={<PreviousEventsPage />} /> },
        { path: 'blog', element: <ProtectedRoute element={<BlogPage />} /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ]);

  return routes;
}

import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent, Container, Grid, Stack, TextField, Typography, Box, Select, MenuItem, FormControl, InputLabel, Switch, FormControlLabel, CircularProgress, IconButton } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { getFirestore, collection, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate, useLocation } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

import firebaseApp from '../utils/firebaseConfig'; // make sure the path is correct
import GroupSelect from '../components/forms/GroupSelect';

import Iconify from '../components/iconify';


export default function NewUserPage() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [officialEmail, setOfficialEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [group, setGroup] = useState([]);
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [profilePic, setProfilePic] = useState('');


    const [location, setLocation] = useState('');
    const [date, setDate] = useState('');
    const [quantity, setQuantity] = useState('');

    const [files, setFiles] = useState([]);

    const [eventStatus, setEventStatus] = useState('closed');

    const fileTypes = ['JPG', 'PNG', 'GIF'];

    const navigate = useNavigate();
    const navLocation = useLocation();

    const userId = new URLSearchParams(navLocation.search).get('id');

    useEffect(() => {
        const fetchUser = async () => {
            if (!userId) return;

            try {
                const db = getFirestore(firebaseApp);
                const userDocRef = doc(db, 'users', userId);
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    const userData = userDocSnapshot.data();
                    setFirstName(userData.firstName || '');
                    setLastName(userData.lastName || '');
                    setEmail(userData.email || '');
                    setDateOfBirth(convertFirestoreTimestampToDate(userData.dateOfBirth) || '')
                    setOfficialEmail(userData.officialEmail || '');
                    setPhoneNumber(userData.phoneNumber || '');
                    setPosition(userData.title || '');
                    setGroup(userData.groupIds || []);
                    setCompany(userData.company || '');
                    setProfilePic(userData.profileImageUrl || '')
                    console.log(userData, "userData")
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUser();
    }, [userId]);

    const onDrop = (acceptedFiles) => {
        // setFiles(acceptedFiles.map(file => Object.assign(file, {
        //     preview: URL.createObjectURL(file)
        // })));
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleDateOfBirthChange = (event) => {
        console.log(event.target.value, "event.target.value")
        setDateOfBirth(event.target.value);
    };

    const handleOfficialEmailChange = (event) => {
        setOfficialEmail(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleGroupChange = (selectedGroup) => {
        console.log(selectedGroup)
        setGroup(selectedGroup);
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
    };

    const handlePositionChange = (event) => {
        setPosition(event.target.value);
    };

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };

    const handleFileUpload = (files) => {
        // Whatever you want to do with the files
        // For instance, you could store them in state:
        setFiles(files);
    };

    const handleEventStatusChange = (event) => {
        setEventStatus(event.target.checked ? 'open' : 'closed');
    };

    const handleProfileChange = (file) => {
        setFile(file);
    };

    function generateRandomCode() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < 6; i += 1) { // Notice the corrected loop increment
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    const saveImageToFirestore = async () => {

        let profileImageUrl = '';
        try {

            // Upload images to Firebase Storage
            const storage = getStorage();
            const storageRef = ref(storage);

            if (file) {
                const coverImageRef = ref(storageRef, file.name);
                await uploadBytes(coverImageRef, file);
                profileImageUrl = await getDownloadURL(coverImageRef);

            }

            return profileImageUrl;

        } catch (error) {
            alert('Error uploading file!')
        }

        return profileImageUrl;
    };

    const saveUserToFirestore = async () => {
        setIsSaving(true);

        const db = getFirestore(firebaseApp);
        let localProfileImage = profilePic

        if (file) {
            const profileImageUrl = await saveImageToFirestore();

            localProfileImage = profileImageUrl;
        }

        try {
            if (userId) {
                // Update existing user
                const userDocumentRef = await doc(db, 'users', userId);
                await updateDoc(userDocumentRef, {
                    firstName,
                    lastName,
                    email,
                    dateOfBirth,
                    officialEmail,
                    phoneNumber,
                    title: position,
                    groupIds: group,
                    company,
                    profileImageUrl: localProfileImage,
                }, { merge: true });

                console.log('User document updated with ID:', userId);
            } else {
                // Create new user
                const userDocumentRef = await addDoc(collection(db, 'users'), {
                    firstName,
                    lastName,
                    email,
                    dateOfBirth,
                    officialEmail,
                    phoneNumber,
                    title: position,
                    groupIds: group,
                    company,
                    signUpCode: generateRandomCode(),
                });

                console.log('User document created with ID:', userDocumentRef.id);
            }
        } catch (error) {
            console.error('Error saving user document:', error);
        } finally {
            setIsSaving(false);
            navigate('/dashboard/user');
        }
    };


    function convertFirestoreTimestampToDate(timestamp) {
        console.log(timestamp, "timestamp")
        if (!timestamp || typeof timestamp.seconds !== 'number' || typeof timestamp.nanoseconds !== 'number') {
            return timestamp;
        }

        const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
        const date = new Date(milliseconds);
        return date.toISOString().slice(0, 10);
    }

    return (
        <>
            <Helmet>
                <title> Events | HelloVIP </title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        User Information
                    </Typography>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <Card>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack>
                                    <Typography variant="h6" sx={{ marginBottom: 2 }}>User Details</Typography>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <TextField fullWidth label="First Name" value={firstName} onChange={handleFirstNameChange} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField fullWidth label="Last Name" value={lastName} onChange={handleLastNameChange} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField fullWidth label="Email" value={email} onChange={handleEmailChange} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField fullWidth label="Phone Number" value={phoneNumber} onChange={handlePhoneNumberChange} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <GroupSelect value={group} onChange={handleGroupChange} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField type="date" fullWidth label="DateOfBirth" value={dateOfBirth} onChange={handleDateOfBirthChange} InputLabelProps={{
                                                shrink: true,
                                            }} />
                                        </Grid>
                                    </Grid>

                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack spacing={2}>
                                    <Typography variant="h6">More Information</Typography>
                                    <TextField fullWidth label="Company" value={company} onChange={handleCompanyChange} />
                                    <TextField fullWidth label="Position" value={position} onChange={handlePositionChange} />
                                    <TextField fullWidth label="Official Email" value={officialEmail} onChange={handleOfficialEmailChange} />
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" style={{ marginBottom: 30 }}>
                                    Profile Picture
                                </Typography>
                                <FileUploader handleChange={handleProfileChange} name="file" types={fileTypes} />
                                {(file && file.type.includes('image/')) || profilePic ? (
                                    <Box display="flex" flexDirection="column" alignItems="left" style={{ marginTop: 30 }}>
                                        <div style={{ position: 'relative', width: 100, height: 100 }}>
                                            <img src={file ? URL.createObjectURL(file) : profilePic} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Cover" />
                                            <IconButton
                                                onClick={() => {
                                                    setFile(null);
                                                    setProfilePic('');
                                                }}
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    background: 'white',
                                                    color: 'red',
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </Box>
                                ) : null}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* Adding the Save User button */}
                <Box sx={{ position: 'fixed', right: 16, bottom: 16 }}>
                    <Button variant="contained" color="primary" onClick={saveUserToFirestore}>
                        Save User
                    </Button>
                </Box>
            </Container>
            {isSaving && (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(255, 255, 255, 0.5)"
                >
                    <CircularProgress color="primary" />
                </Box>
            )}
        </>
    );
}

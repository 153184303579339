import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import GroupSelect from '../components/forms/GroupSelect';

export default function NewPushNotificationPage() {
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [group, setGroup] = useState([]);
  const [scheduled, setScheduled] = useState(false);
  const [officialEmail, setOfficialEmail] = useState(new Date());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleGroupChange = (event) => {
    setGroup(event);
  };

  const handleScheduledChange = (event) => {
    setScheduled(event.target.checked);
  };

  const handleOfficialEmailChange = (event) => {
    setOfficialEmail(event.target.value);
  };

  const handleSubmit = async () => {
    if (!company || !position || group.length === 0 || (scheduled && !officialEmail)) {
      alert('Please fill out all fields before submitting.');
      return;
    }

    try {
      setIsSubmitting(true);

      const db = getFirestore();

      // Create a new push notification object
      const pushNotification = {
        title: company,
        message: position,
        groupIds: group,
        scheduled,
        scheduledDate: scheduled ? new Date() : officialEmail,
        createdAt: new Date(),
      };

      // Save the push notification to the Firestore collection
      const docRef = await addDoc(collection(db, 'pushNotifications'), pushNotification);
      console.log('Push notification saved with ID: ', docRef.id);

      // Display an alert
      alert('Push notification saved successfully');

      // Navigate to the desired page
      navigate('/dashboard/push-notifications');
    } catch (error) {
      console.error('Error saving push notification:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Push Notification Information | HelloVIP</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Push Notification
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Card>
              <CardContent sx={{ padding: 2 }}>
                <Stack spacing={2}>
                  <Typography variant="h6">Notification Information</Typography>
                  <TextField fullWidth label="Title" value={company} onChange={handleCompanyChange} />
                  <TextField fullWidth label="Message" value={position} onChange={handlePositionChange} />
                  <FormControl fullWidth>
                    <GroupSelect value={group} onChange={handleGroupChange} />
                  </FormControl>
                  <FormControlLabel
                    control={<Switch checked={scheduled} onChange={handleScheduledChange} />}
                    label="Scheduled"
                  />
                  {scheduled && (
                    <TextField
                      fullWidth
                      label="Date and Time"
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      value={officialEmail}
                      onChange={handleOfficialEmailChange}
                    />
                  )}
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isSubmitting || !company || !position || group.length === 0 || (scheduled && !officialEmail)}
                  >
                    {isSubmitting ? <CircularProgress size={24} /> : (scheduled ? 'Schedule' : 'Send Instantly')}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { getFirestore, collection, addDoc, serverTimestamp, query, orderBy, limit, getDocs, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import firebaseApp from '../utils/firebaseConfig';
import GroupSelect from '../components/forms/GroupSelect';

export default function NewCategoryPage() {
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [group, setGroup] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const categoryId = new URLSearchParams(location.search).get('id');
  const isEditing = categoryId;

  useEffect(() => {
    const fetchCategoryData = async () => {
      if (isEditing) {
        const db = getFirestore(firebaseApp);
        const categoryDocRef = doc(db, 'categories', categoryId);
        const categoryDoc = await getDoc(categoryDocRef);
        if (categoryDoc.exists()) {
          const categoryData = categoryDoc.data();
          setCompany(categoryData.name);
          setPosition(categoryData.position);
          setGroup(categoryData.groupIds);
        }
      }
    };

    fetchCategoryData();
  }, [categoryId, isEditing]);

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handlePositionChange = (event) => {
    const newPosition = parseInt(event.target.value, 10);
    setPosition(newPosition);
  };

  const handleGroupChange = (selectedGroup) => {
    setGroup(selectedGroup);
  };

  const handleSubmit = async () => {
    if (!company || group.length === 0) {
      alert('Please fill out all required fields');
      return;
    }

    try {
      setIsLoading(true);

      const db = getFirestore(firebaseApp);
      const categoriesRef = collection(db, 'categories');

      if (isEditing) {
        const categoryDocRef = doc(db, 'categories', categoryId);
        await updateDoc(categoryDocRef, {
          name: company,
          groupIds: group,
          position
        });
      } else {
        const categoriesQuery = query(categoriesRef, orderBy('position', 'desc'), limit(1));
        const categoriesSnapshot = await getDocs(categoriesQuery);
        const count = categoriesSnapshot.docs.length > 0 ? categoriesSnapshot.docs[0].data().position : 0;

        const category = {
          createdAt: serverTimestamp(),
          name: company,
          groupIds: group,
          position,
          docId: '',
        };

        const docRef = await addDoc(categoriesRef, category);

        const updatedCategory = {
          ...category,
          docId: docRef.id,
        };

        await updateDoc(doc(db, 'categories', docRef.id), updatedCategory);
      }

      setPosition(null)
      setCompany('');
      setGroup([]);

      alert('Category successfully saved');
      navigate('/dashboard/categories');
    } catch (error) {
      console.error('Error adding/editing category document: ', error);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Push Notification Information | HelloVIP
        </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Category
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Card>
              <CardContent sx={{ padding: 2 }}>
                <Stack spacing={2}>
                  <Typography variant="h6">Category Information</Typography>
                  <TextField fullWidth label="Name" value={company} onChange={handleCompanyChange} />

                  <FormControl fullWidth>
                    <GroupSelect value={group} onChange={handleGroupChange} />
                  </FormControl>

                  <TextField type="number" fullWidth label="Position" value={position} onChange={handlePositionChange} />


                  <Button variant="contained" onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      isEditing ? 'Save Changes' : 'Save Category'
                    )}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}


import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField
} from '@mui/material';
import moment from 'moment';
import { getFirestore, collection, query, getDocs, doc, onSnapshot, where, getDoc, updateDoc } from 'firebase/firestore';
import { Navigation } from '@mui/icons-material';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'ticket', label: '# of Tickets', alignRight: false },
  { id: 'request', label: 'Request', alignRight: false },

];

const ATTENDANCE_DATA = [
  { id: 1, name: 'name1', ticket: '12' },
  { id: 2, name: 'name2', ticket: '32' },
  { id: 3, name: 'name3', ticket: '3' },
  { id: 4, name: 'name4', ticket: '2' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (user) => {
      const name = user.fullName;
      return (name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AttendancePage() {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [remainingTickets, setRemainingTickets] = useState(0);
  const [openModal, setOpenModal] = useState(true);
  const [groups, setGroups] = useState([]);
  const [row, setRow] = useState({});
  const navigate = useNavigate();


  const db = getFirestore();
  const navLocation = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(navLocation.search);
    const eventId = params.get('id');

    if (eventId) {
      const requestsCollection = collection(db, "requests");
      const q = query(requestsCollection, where("event", "==", eventId));

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const fetchedEventsPromises = querySnapshot.docs.map(async (doc) => {
          const requestData = doc.data();
          requestData.id = doc.id;
          const userId = requestData.userId;

          // Fetch user data
          const usersCollection = collection(db, "users");
          const usersQ = query(usersCollection, where("userId", "==", userId));
          const userQuerySnapshot = await getDocs(usersQ);

          const fetchedEvents = userQuerySnapshot.docs.map((userDoc) => {
            const userData = userDoc.data();
            const fullName = `${userData.firstName} ${userData.lastName}`;
            const phoneNum = userData.phoneNumber;
            const email = userData.officialEmail;
            const company = userData.company
            const companyTitle = userData.title
            return { ...requestData, fullName, email, phoneNum, company, companyTitle };
          });



          return fetchedEvents;
        });

        // Fetch event data
        const eventDocRef = doc(db, 'events', eventId);
        const eventDoc = await getDoc(eventDocRef);
        if (eventDoc.exists()) {
          const eventData = eventDoc.data();
          setRemainingTickets(parseInt(eventData.remaining, 10));
        }

        const allFetchedEvents = await Promise.all(fetchedEventsPromises);
        console.log(allFetchedEvents);
        setEvents(allFetchedEvents.flat());
      });

    } else {
      console.log('ID not provided in URL');
    }
  }, [db, navLocation.search]);



  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setRow(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ATTENDANCE_DATA.map((event) => event.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    console.log(event.target.value)
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleApproveClick = async (eventId) => {
    // Update the status in Firestore
    const requestDocRef = doc(db, 'requests', eventId);
    await updateDoc(requestDocRef, {
      status: 'Accepted',
      remaining: remainingTickets - 1,
    });

    alert("Success!");
  };

  const handleDenyClick = async (eventId) => {
    // Update the status in Firestore
    const requestDocRef = doc(db, 'requests', eventId);
    await updateDoc(requestDocRef, {
      status: 'Declined',
    });

    alert("Success!");
  };



  const handleRaffleClick = async () => {
    const eligibleRequests = events.filter(e => e.status === 'Pending');
    const winners = [];

    if (eligibleRequests.length > 0 && remainingTickets > 0) {
      while (winners.length < remainingTickets && eligibleRequests.length > 0) {
        const randomIndex = Math.floor(Math.random() * eligibleRequests.length);
        const winner = eligibleRequests[randomIndex];
        winners.push(winner);

        // Remove the selected winner from eligible requests
        eligibleRequests.splice(randomIndex, 1);
      }

      // Update the status of winners in Firestore
      winners.forEach(async (winner) => {
        const requestDocRef = doc(db, 'requests', winner.id);
        await updateDoc(requestDocRef, { status: 'Accepted' });
      });

      // Update the status of non-winners to Declined
      eligibleRequests.forEach(async (nonWinner) => {
        const requestDocRef = doc(db, 'requests', nonWinner.id);
        await updateDoc(requestDocRef, { status: 'Declined' });
      });

      // Update the remaining tickets in Firestore and state
      const updatedRemainingTickets = remainingTickets - winners.length;
      const eventDocRef = doc(db, 'events', events[0].event);
      await updateDoc(eventDocRef, { remaining: updatedRemainingTickets.toString() });
      setRemainingTickets(updatedRemainingTickets);

      // Optionally, alert or display the winners
      alert(`Raffle completed! ${winners.length} winners have been selected and other pending requests have been declined.`);
    } else {
      // Optionally, alert or display a message when there are no eligible requests or remaining tickets
      alert(`Raffle cannot be completed. Ensure there are pending requests and remaining tickets.`);
    }
  };

  const handleSingleRaffleClick = async () => {
    const eligibleRequests = events.filter(e => e.status === 'Pending');

    if (eligibleRequests.length > 0 && remainingTickets > 0) {
      const randomIndex = Math.floor(Math.random() * eligibleRequests.length);
      const winner = eligibleRequests[randomIndex];
      console.log(winner)

      // Update the status of the selected winner in Firestore
      const requestDocRef = doc(db, 'requests', winner.id);
      await updateDoc(requestDocRef, { status: 'Accepted' });

      // Update the remaining tickets in Firestore and state
      const updatedRemainingTickets = remainingTickets - 1;
      const eventDocRef = doc(db, 'events', events[0].event);
      await updateDoc(eventDocRef, { remaining: updatedRemainingTickets.toString() });
      setRemainingTickets(updatedRemainingTickets);

      // Optionally, alert or display the winner
      alert(`Raffle completed! The selected winner is: ${winner.fullName}. Phone: ${winner.phoneNum}. Company: ${winner.company}. Email: ${winner.email}.`);
    } else {
      // Optionally, alert or display a message when there are no eligible requests or remaining tickets
      alert(`Raffle cannot be completed. Ensure there are pending requests and remaining tickets.`);
    }
  };

  function downloadCSV(arrayData, fileName) {
    // Define the CSV header
    const csvHeader = 'Name,Email\n';

    // Convert array data to CSV rows
    const csvRows = arrayData.map(item => `${item.fullName},${item.email}`).join('\n');

    // Combine the header and rows
    const csvString = csvHeader + csvRows;

    // Create a Blob with CSV data
    const blob = new Blob([csvString], { type: 'text/csv' });

    // Create a link element
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    // Set link's attributes and initiate download
    link.setAttribute("href", url);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click(); // Trigger the download

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }




  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - events.length) : 0;

  const filteredEvents = applySortFilter(events, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredEvents.length && !!filterName;

  return (
    <>
      <Helmet>
        <title>Attendance | HelloVIP</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Attendance
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleRaffleClick}>
            Start Raffle
          </Button>

          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleSingleRaffleClick}>
            Start Single Raffle
          </Button>

          <Button variant="contained" onClick={() => downloadCSV(filteredEvents, "filtered_events")}>
            Download CSV
          </Button>
        </Stack>

        <Card>
          <UserListToolbar searchName="Search attendance..." numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={ATTENDANCE_DATA.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={ATTENDANCE_DATA.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                    const { id, name, ticket, fullName, email, numOfTickets, status, phoneNum, company, title, companyTitle } = item;
                    const selectedEvent = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedEvent}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedEvent} onChange={(item) => handleClick(item, name)} />
                        </TableCell>
                        <TableCell align="left">
                          {fullName}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography align="left" variant="subtitle2" noWrap>
                            {email}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {company}
                        </TableCell>
                        <TableCell align="left">
                          {companyTitle}
                        </TableCell>
                        <TableCell align="left">{numOfTickets}</TableCell>

                        {status === 'Pending' && remainingTickets > 0 && <TableCell align="left">
                          <Button variant="contained" color="info" onClick={() => handleApproveClick(id)}>
                            Accept
                          </Button>
                          <Button variant="contained" sx={{ marginLeft: '5px' }} color="error" onClick={() => handleDenyClick(id)}>
                            Deny
                          </Button>
                        </TableCell>}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: 'center' }}>
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={ATTENDANCE_DATA.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

    </>
  );
}

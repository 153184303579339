import { useEffect, useState, useCallback } from 'react';
import { getFirestore, collection, query, getDocs, doc, onSnapshot, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { useNavigate, useLocation } from 'react-router-dom';

import { FileUploader } from "react-drag-drop-files";



import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  IconButton,
  CircularProgress,
  Checkbox
} from '@mui/material';




import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

import { Helmet } from 'react-helmet-async';
import DeleteIcon from '@mui/icons-material/Delete';

import Iconify from '../components/iconify';

import GroupSelect from '../components/forms/GroupSelect';
import CategorySelect from '../components/forms/CategorySelect';

import firebaseApp from '../utils/firebaseConfig'; // make sure the path is correct


export default function NewEventPage() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [group, setGroup] = useState([]);
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [eventStartDate, setEventStartDate] = useState('');
  const [eventEndDate, setEventEndDate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [showComingSoon, setShowComingSoon] = useState('');
  const [file, setFile] = useState(null);
  const [secondFile, setSecondFile] = useState(null);
  const [thirdFile, setThirdFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [saving, setSaving] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [eventImages, setEventImages] = useState([]);
  const [eventId, setEventId] = useState('');
  const [instructions, setInstructions] = useState('');
  const [myEvent, setMyEvent] = useState({})
  const [maxTicketsPerPerson, setMaxTicketsPerPerson] = useState('1')

  const fileTypes = ['JPG', 'PNG', 'GIF'];

  const [eventStatus, setEventStatus] = useState('closed');

  const navigate = useNavigate();
  const navLocation = useLocation();

  const defaultEventId = new URLSearchParams(navLocation.search).get('id');

  useEffect(() => {
    const fetchUser = async () => {
      if (!defaultEventId) return;

      try {
        const db = getFirestore(firebaseApp);
        const userDocRef = doc(db, 'events', defaultEventId);
        const eventDocSnapshot = await getDoc(userDocRef);

        if (eventDocSnapshot.exists()) {
          const eventData = eventDocSnapshot.data();
          console.log(eventData)
          setMyEvent(eventData)
          setTitle(eventData.title || '');
          setEventId(defaultEventId)
          setDescription(eventData.description || '');
          setQuantity(eventData.quantity || '');
          setLocation(eventData.location || '');
          setInstructions(eventData.instructions || '');
          setGroup(eventData.groupIds || []);
          setCategory(eventData.category || '');
          setEventStartDate(eventData.eventStartDate || '');
          setEventEndDate(eventData.eventEndDate || '');
          setDate(eventData.date || '');
          setEventStatus(eventData.eventStatus || '');
          setEventImages(eventData.images || []);
          setMaxTicketsPerPerson(eventData.maxTickets || '1')
          setShowComingSoon(eventData.showComingSoon || false)
          console.log(eventData, "eventData")
          // setLastName(userData.lastName || '');
          // setEmail(userData.email || '');
          // setOfficialEmail(userData.officialEmail || '');
          // setPhoneNumber(userData.phoneNumber || '');
          // setPosition(userData.title || '');
          // setGroup(userData.groupIds || []);
          // setCompany(userData.company || '');
          // setProfilePic(userData.profileImageUrl || '')
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, [defaultEventId]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleinstructionChange = (event) => {
    setInstructions(event.target.value);
  };

  const handleGroupChange = (selectedGroup) => {
    setGroup(selectedGroup);
  };

  const handleCategoryChange = (selectedGroup) => {
    setCategory(selectedGroup);
  };

  const handleShowComingSoonChange = (event) => {
    setShowComingSoon(!showComingSoon);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleRegistrationStartDateChange = (event) => {
    setEventStartDate(event.target.value);
  };

  const handleMaxTicketsPerPersonChange = (event) => {
    setMaxTicketsPerPerson(event.target.value);
  };

  const handleRegistrationEndDateChange = (event) => {
    setEventEndDate(event.target.value);
  };

  const handleDateChange = (event) => {
    console.log(event.target.value)
    setDate(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleFileUpload = (files) => {
    setFiles(files);
  };

  const handleEventStatusChange = (event) => {
    setEventStatus(event.target.checked ? 'open' : 'closed');
  };

  const handleCoverChange = (file) => {
    setFile(file);
  };

  const handleSecondImageChange = (file) => {
    setSecondFile(file);
  };

  const handleThirdImageChange = (file) => {
    setThirdFile(file);
  };

  const handleSaveEvent = async () => {
    try {
      setSaving(true);

      // Upload images to Firebase Storage
      const storage = getStorage();
      const storageRef = ref(storage);

      const myImages = [];

      if (file) {
        const coverImageRef = ref(storageRef, file.name);
        await uploadBytes(coverImageRef, file);
        const coverImageUrl = await getDownloadURL(coverImageRef);
        myImages.push(coverImageUrl);
      } else {
        alert('Please upload a cover image');
        return
      }

      if (secondFile) {
        const secondImageRef = ref(storageRef, secondFile.name);
        await uploadBytes(secondImageRef, secondFile);
        const secondImageUrl = await getDownloadURL(secondImageRef);
        myImages.push(secondImageUrl);
      }

      if (thirdFile) {
        const thirdImageRef = ref(storageRef, thirdFile.name);
        await uploadBytes(thirdImageRef, thirdFile);
        const thirdImageUrl = await getDownloadURL(thirdImageRef);
        myImages.push(thirdImageUrl);
      }

      // Create the event object with the uploaded image URLs
      const event = {
        title,
        description,
        groupIds: group,
        location,
        eventStartDate,
        eventEndDate,
        date,
        category,
        quantity,
        maxTickets: maxTicketsPerPerson,
        remaining: quantity,
        images: myImages,
        users: [],
        instructions,
        eventStatus,
        showComingSoon
      };

      // Save the event to Firestore
      const db = getFirestore();
      await addDoc(collection(db, 'events'), event);

      // Clear the form fields
      setTitle('');
      setDescription('');
      setGroup([]);
      setLocation('');
      setEventStartDate('');
      setEventEndDate('');
      setDate('');
      setQuantity('');
      setMaxTicketsPerPerson('');
      setFile(null);
      setSecondFile(null);
      setThirdFile(null);

      setSaving(false);
      setAlertOpen(true);
      setShowComingSoon(false)

      navigate('/dashboard/events');

      console.log('Event saved successfully!');
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const handleEditEvent = async () => {
    try {
      setSaving(false);

      // Upload images to Firebase Storage
      const storage = getStorage();
      const storageRef = ref(storage);

      const myImages = eventImages;

      if (file && eventImages.length > 0) {
        const coverImageRef = ref(storageRef, file.name);
        await uploadBytes(coverImageRef, file);
        const coverImageUrl = await getDownloadURL(coverImageRef);
        myImages[0] = (coverImageUrl);
      }

      if (secondFile) {
        const secondImageRef = ref(storageRef, secondFile.name);
        await uploadBytes(secondImageRef, secondFile);
        const secondImageUrl = await getDownloadURL(secondImageRef);
        myImages[1] = secondImageUrl;
      }

      if (thirdFile) {
        const thirdImageRef = ref(storageRef, thirdFile.name);
        await uploadBytes(thirdImageRef, thirdFile);
        const thirdImageUrl = await getDownloadURL(thirdImageRef);
        // myImages.push(thirdImageUrl);
        myImages[2] = thirdImageUrl;
      }

      // Create the event object with the uploaded image URLs
      const event = {
        title,
        description,
        groupIds: group,
        location,
        eventStartDate,
        eventEndDate,
        date,
        category,
        quantity,
        remaining: quantity,
        images: myImages,
        eventStatus,
        instructions,
        maxTickets: maxTicketsPerPerson,
        showComingSoon
      };
      console.log(event.date)
      // Save the event to Firestore
      const db = getFirestore();
      const eventRef = doc(db, 'events', defaultEventId);
      await updateDoc(eventRef, event);


      setSaving(false);
      alert('Event saved successfully!')
      navigate('/dashboard/events');
      console.log('Event saved successfully!');
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const deleteEventImage = (index) => {
    // Make sure you have access to the eventImages array
    const updatedEventImages = [...eventImages]; // Create a new array to avoid mutating the original array
    updatedEventImages.splice(index, 1); // Remove the element at the specified index
    setEventImages(updatedEventImages); // Update the state with the updated array
  };

  return (
    <>
      <Helmet>
        <title> Events | HelloVIP </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Event Information
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={eventStatus === 'open'}
                onChange={handleEventStatusChange}
                name="eventStatus"
                color="primary"
              />
            }
            label={eventStatus === 'open' ? 'Open' : 'Closed'}
          />
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  <Typography variant="h6">Event Details</Typography>
                  <TextField fullWidth label="Title" value={title} onChange={handleTitleChange} />
                  <TextField fullWidth label="Description" value={description} onChange={handleDescriptionChange} />
                  <TextField fullWidth label="Instructions (Reedem)" value={instructions} onChange={handleinstructionChange} />
                  <Grid container >
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <GroupSelect value={group} onChange={handleGroupChange} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <CategorySelect value={category} onChange={handleCategoryChange} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    label="Tickets Available"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={quantity}
                    onChange={handleQuantityChange}
                    inputProps={{ min: 0 }}
                  />
                  {/* New TextField for maxTicketsPerPerson */}
                  <TextField
                    fullWidth
                    label="Max Tickets Per Person"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={maxTicketsPerPerson}
                    onChange={handleMaxTicketsPerPersonChange}
                    inputProps={{ min: 1 }} // Assuming you want at least 1 ticket to be purchasable
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardContent sx={{ padding: 2 }}>
                <Stack spacing={2}>
                  <Typography variant="h6">Location and Quantity</Typography>
                  <TextField fullWidth label="Location" value={location} onChange={handleLocationChange} />
                  <TextField
                    fullWidth
                    label="Registration Start Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={eventStartDate}
                    onChange={handleRegistrationStartDateChange}
                  />
                  <TextField
                    fullWidth
                    label="Registration End Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={eventEndDate}
                    onChange={handleRegistrationEndDateChange}
                  />
                  <TextField fullWidth label="Event Date" type="date" InputLabelProps={{ shrink: true }} value={date} onChange={handleDateChange} />
                  <FormControlLabel
                    control={<Checkbox checked={showComingSoon} onChange={handleShowComingSoonChange} />}
                    label="Show coming soon"
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" style={{ marginBottom: 30 }}>
                        Cover Image (Required)
                      </Typography>
                      <FileUploader handleChange={handleCoverChange} name="file" types={fileTypes} />
                      {file && file.type.includes('image/') && (
                        <Box display="flex" flexDirection="column" alignItems="left" style={{ marginTop: 30 }}>
                          <div style={{ position: 'relative', width: 100, height: 100 }}>
                            <img src={URL.createObjectURL(file)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Cover" />
                            <IconButton
                              onClick={() => setFile(null)}
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'white',
                                color: 'red',
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Box>
                      )}
                      {!file && eventImages && eventImages[0] && (
                        <Box display="flex" flexDirection="column" alignItems="left" style={{ marginTop: 30 }}>
                          <div style={{ position: 'relative', width: 100, height: 100 }}>
                            <img src={eventImages[0]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Cover" />
                            <IconButton
                              onClick={() => deleteEventImage(0)}
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'white',
                                color: 'red',
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" style={{ marginBottom: 30 }}>
                        2nd Image (Optional)
                      </Typography>
                      <FileUploader handleChange={handleSecondImageChange} name="file" types={fileTypes} />
                      {secondFile && secondFile.type.includes('image/') && (
                        <Box display="flex" flexDirection="column" alignItems="left" style={{ marginTop: 30 }}>
                          <div style={{ position: 'relative', width: 100, height: 100 }}>
                            <img src={URL.createObjectURL(secondFile)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Second" />
                            <IconButton
                              onClick={() => setSecondFile(null)}
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'white',
                                color: 'red',
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Box>
                      )}
                      {!secondFile && eventImages && eventImages[1] && (
                        <Box display="flex" flexDirection="column" alignItems="left" style={{ marginTop: 30 }}>
                          <div style={{ position: 'relative', width: 100, height: 100 }}>
                            <img src={eventImages[1]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Second" />
                            <IconButton
                              onClick={() =>
                                deleteEventImage(1)
                              }
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'white',
                                color: 'red',
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" style={{ marginBottom: 30 }}>
                        3rd Image (Optional)
                      </Typography>
                      <FileUploader handleChange={handleThirdImageChange} name="file" types={fileTypes} />
                      {thirdFile && thirdFile.type.includes('image/') && (
                        <Box display="flex" flexDirection="column" alignItems="left" style={{ marginTop: 30 }}>
                          <div style={{ position: 'relative', width: 100, height: 100 }}>
                            <img src={URL.createObjectURL(thirdFile)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Third" />
                            <IconButton
                              onClick={() => setThirdFile(null)}
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'white',
                                color: 'red',
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Box>
                      )}
                      {!thirdFile && eventImages && eventImages[2] && (
                        <Box display="flex" flexDirection="column" alignItems="left" style={{ marginTop: 30 }}>
                          <div style={{ position: 'relative', width: 100, height: 100 }}>
                            <img src={eventImages[2]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Third" />
                            <IconButton
                              onClick={() => deleteEventImage(2)}
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'white',
                                color: 'red',
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={defaultEventId ? handleEditEvent : handleSaveEvent}>
                Save Event
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {saving && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(255, 255, 255, 0.5)"
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {alertOpen && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0, 0, 0, 0.5)"
        >
          <Typography variant="h6" component="div" sx={{ color: '#fff' }}>
            Event saved successfully!
          </Typography>
        </Box>
      )}
    </>
  );
}

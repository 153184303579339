import { faker } from '@faker-js/faker';
import { sample, without } from 'lodash';

// ----------------------------------------------------------------------

const categoryNames = ['Silver', 'Gold', 'Platinum', 'Bronze', 'Sponsors', 'New Clients'];

const categories = categoryNames.map((name) => ({
  id: faker.datatype.uuid(),
  name,
  type: sample(['All', 'Gold', 'Silver', 'Bronze']),
  qty: faker.datatype.number({ min: 1, max: 100 }), // Generate a random number between 1 and 100
  date: faker.date.future(),
  location: faker.address.city(),
  status: sample(['Open', 'Closed']), // Add the status property with values "open" or "closed"
}));

const remainingNames = without(categoryNames, ...categories.map((category) => category.name));

categories.forEach((category) => {
  if (remainingNames.length > 0) {
    const randomName = sample(remainingNames);
    category.name = randomName;
    remainingNames.splice(remainingNames.indexOf(randomName), 1);
  }
});

export default categories;

import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, doc, setDoc, getDoc } from 'firebase/firestore';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Alert } from '@mui/lab';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import firebaseApp from '../utils/firebaseConfig';

const NewGroupPage = () => {
  const [groupName, setGroupName] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const groupId = new URLSearchParams(location.search).get('id');
  const isEditing = groupId;

  useEffect(() => {
    const fetchGroupData = async () => {
      if (isEditing) {
        const db = getFirestore(firebaseApp);
        const groupDocRef = doc(db, 'groups', groupId);
        const groupDoc = await getDoc(groupDocRef);
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          setGroupName(groupData.name);
        }
      }
    };

    fetchGroupData();
  }, [groupId, isEditing]);

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const handleSaveGroup = async () => {
    const db = getFirestore(firebaseApp);

    try {
      if (!groupName) { // Check if groupName is empty
        alert('Please enter a group name');
        return;
      }

      setIsLoading(true);

      if (isEditing) {
        const groupDocRef = doc(db, 'groups', groupId);
        await setDoc(groupDocRef, {
          name: groupName,
        });
      } else {
        const newDocRef = await addDoc(collection(db, 'groups'), {
          name: groupName,
          createdAt: new Date(),
        });
        console.log('Document written with ID: ', newDocRef.id);
      }

      alert('Group successfully saved');
      navigate('/dashboard/groups');

    } catch (error) {
      console.error('Error adding/updating document: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    navigate('/dashboard/groups');
  };

  return (
    <>
      <Helmet>
        <title>Group Information | HelloVIP</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Group
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Card>
              <CardContent sx={{ padding: 2 }}>
                <Stack spacing={2}>
                  <Typography variant="h6">Group Information</Typography>
                  <TextField fullWidth label="Name" value={groupName} onChange={handleGroupNameChange} />
                  <Button
                    variant="contained"
                    onClick={handleSaveGroup}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      isEditing ? 'Update Group' : 'Save Group'
                    )}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NewGroupPage;

import React, { useEffect, useState } from 'react';
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import firebaseApp from '../../utils/firebaseConfig';

export default function GroupSelect({ value, onChange }) {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const db = getFirestore(firebaseApp);
        const groupsCollection = collection(db, 'groups');
        const groupsSnapshot = await getDocs(groupsCollection);
        const groupData = groupsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setGroups(groupData);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };
    fetchGroups();
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel className='input-label-select'>Group</InputLabel>
      <Select
        multiple
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        <MenuItem selected disabled>Select a group</MenuItem>
        {groups.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
